<template>
	<Page>
		<Header :light="light ? false : true">
			<Stack justify="spaceBetween">
				<Stack height="40px">
					<Icon icon="storm" :color="light ? 'light' : 'primary'" size="medium"/>
					<Heading size="4" :whiteout="light ? true : false" uppercase>windermere</Heading>
				</Stack>
				<Stack justify="center" space="3" visibility="hide show@md" height="40px">
					<Link text="Home" :color="light ? 'light' : ''" display="inline" url="/windermere" />
					<Link text="Company" :color="light ? 'light' : ''" display="inline" url="/windermere/company" />
					<Link text="Services" :color="light ? 'light' : ''" display="inline" url="/windermere/services" />
					<Link text="Blog" :color="light ? 'light' : ''" display="inline" url="/windermere/blog" />
					<Link text="Contact" :color="light ? 'light' : ''" display="inline" url="/windermere/contact" />
				</Stack>
				<Stack justify="right" space="2" visibility="hide show@md" height="40px">
					<Icon icon="shopping_bag" :color="light ? 'light' : ''" />
					<Icon icon="search" :color="light ? 'light' : ''" />
					<Icon icon="menu" :color="light ? 'light' : ''" />
				</Stack>
				<Stack justify="right" space="2" visibility="show hide@md" height="40px" v-click-outside="setClose">
					<Link @click.native="setOpen()" display="inline" url="">
						<Icon icon="menu" :color="light ? 'light' : 'dark'" size="medium" v-if="!isOpen" />
						<Icon icon="menu_open" :color="light ? 'light' : 'dark'" size="medium" v-if="isOpen" />
					</Link>
					<Dropdown :open="isOpen">
						<Stack direction="column row@sm" space="1">
							<Link text="Home" display="inline" url="/windermere" />
							<Link text="Company" display="inline" url="/windermere/company" />
							<Link text="Services" display="inline" url="/windermere/services" />
							<Link text="Blog" display="inline" url="/windermere/blog" />
							<Link text="Contact" display="inline" url="/windermere/contact" />
						</Stack>
					</Dropdown>
				</Stack>
			</Stack>
		</Header>
		<Body>
			<slot></slot>
		</Body>
		<Footer>
			<Section color="light">
				<Stack direction="column row@sm" justify="spaceBetween" align="top" space="3 1@sm">

					<Stack space="1">
						<Icon icon="storm" color="primary" size="medium"/>
						<Heading size="4" uppercase>windermere</Heading>
					</Stack>

					<Stack direction="column row@sm" width="50%" justify="center spaceEvenly@md" space="3">

						<Stack direction="column" width="150px" align="left" space="1 3@sm">
							<Heading size="5">Company</Heading>
							<Stack direction="column" align="left" space="1">
								<Link text="Home" url="/windermere" />
								<Link text="About Us" url="/windermere/company" />
								<Link text="Meet Our Team" url="/windermere/company" />
								<Link text="Support" url="/windermere" />
								<Link text="Contact" url="/windermere/contact" />
							</Stack>
						</Stack>

						<Stack direction="column" width="150px" align="left" space="1 3@sm">
							<Heading size="5">Services</Heading>
							<Stack direction="column" align="left" space="1">
								<Link text="FAQ" url="/windermere" />
								<Link text="Blog" url="/windermere/blog" />
								<Link text="Services" url="/windermere/services" />
								<Link text="Case Studies" url="/windermere" />
								<Link text="Privacy Policy" url="/windermere" />
							</Stack>
						</Stack>

					</Stack>
				</Stack>
			</Section>
			<Section color="tertiary" size="small">
				<Stack direction="column row@sm" justify="spaceBetween" space="1">
					<Heading size="6" align="center">Copyright © 2022. Designed by Anna Robbins</Heading>
					<Stack justify="right" space="3" width="auto">
						<Link text="Facebook" url="/" display="inline" />
						<Link text="Twitter" url="/" display="inline" />
						<Link text="Instagram" url="/" display="inline" />
					</Stack>
				</Stack>
			</Section>
		</Footer>
	</Page>
</template>

<script>
export default {
	props: {
		light: Boolean,
	},
  data() {
    return {
			isOpen: false,
		}
  },
	methods: {
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss" scoped>

</style>