<template>
  <PageTemplate>
		
		<Section color="light">
			<Grid>
				<GridContainer size="4">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="mark_as_unread" size="large" color="primary" />
								<Heading size="5">How can we help you?</Heading>
								<Paragraph><Link text="Send us an Email" display="inline" url="/windermere/contact" /></Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="4">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="perm_phone_msg" size="large" color="primary" />
								<Heading size="5">Feel free to get in touch?</Heading>
								<Paragraph><Link text="Give us a call today" display="inline" url="/windermere/contact" /></Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="4">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="request_quote" size="large" color="primary" />
								<Heading size="5">Ready to request a quote?</Heading>
								<Paragraph><Link text="Describe your project" display="inline" url="/windermere/contact" /></Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="6">
					<Heading size="5">Send us a message</Heading>
					<Form>
						<FormContainer size="12">
							<Input placeholder="Your name" />
						</FormContainer>
						<FormContainer size="12">
							<Input placeholder="Your e-mail" />
						</FormContainer>
						<FormContainer size="12">
							<Textarea placeholder="Message..." />
						</FormContainer>
					</Form>
					<Button label="Send" color="secondary" />
				</GridContainer>
				<GridContainer size="3">
					<Stack direction="column" align="left" space="1 3@sm">
						<Stack direction="column" align="left" space="1">
							<Heading size="5">General Contact</Heading>
							<Paragraph>1 (800) 123-4567 <br/> 1234 Street Rd. <br/> Las Vegas NV 89013 <br/> general@example.com</Paragraph>
						</Stack>
						<Stack direction="column" align="left" space="1">
							<Heading size="5">Office</Heading>
							<Paragraph>1234 Street Rd. <br/> Las Vegas NV 89013</Paragraph>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="3">
					<Stack direction="column" align="left" space="1 3@sm">
						<Stack direction="column" align="left" space="1">
							<Heading size="5">New Business</Heading>
							<Paragraph>1 (800) 123-4567 <br/> 1234 Street Rd. <br/> Las Vegas NV 89013 <br/> new.business@example.com</Paragraph>
						</Stack>
						<Stack direction="column" align="left" space="1">
							<Heading size="5">Careers</Heading>
							<Paragraph>careers@example.com</Paragraph>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section image="theme-wm/marble.jpg">
			<Grid>
				<GridContainer size="12 7@md">
					<Card size="xlarge">
						<CardSection>
							<Heading size="6" uppercase>Our Answers</Heading>
							<Heading size="3">Frequently Asked Questions</Heading>
							<Stack direction="column" align="left" space="2">
								<Stack direction="column" align="left">
									<Accordion label="What types of companies do you work with?" border="none">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="Do you write business plans for startups?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="What industries do you specialize in?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="What types of companies do you work with?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
								</Stack>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 5@md">
					<Stack visibility="hide show@md">
						<Images width="100%" height="100%" image="theme-wm/home4.jpg" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
